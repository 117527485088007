import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import logo from "./assets/images/logo_p.png";
import bg from "./assets/images/background_02.png";

import { Button, Modal, ListGroup, Form, Row, Col} from 'react-bootstrap';


export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #FFCC80;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  font-family: "roboto";
  color: #000000;
  width: 200px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(212, 172, 13, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(212, 172, 13, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(212, 172, 13, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  @media (min-width: 600px) {
    width: 200px;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 300px;
  height: 300px;
  @media (min-width: 600px) {
    width: 350px;
    height: 350px;
  }
  border: 1px;
  border-width: 1px;
  border-color: #FFCC80;
  border-style: solid;
  transition: width 0.5s;
  transition: height 0.5s;
`;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{backgroundColor: "#FFCC80"}}>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize: 16}}>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {props.jsonobj ? (
          <>
          <Form>
            <Form.Group className="mb-3">
              <Row>
                <Col sm="9">
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Wraith Name</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.name}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col sm="3">
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Edition</ListGroup.Item>
                    <ListGroup.Item>{`No. ${props.jsonobj.edition}`}</ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Body</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[0].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Head</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[1].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Mouth</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[2].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <s.SpacerMedium />
              <Row>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Eyes</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[3].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Nose</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[4].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Crown</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[5].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <s.SpacerMedium />
              <Row>
                <Col sm="4">
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>Frame</ListGroup.Item>
                    <ListGroup.Item>{props.jsonobj.attributes[6].value}</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col sm="8">
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled>File MD5 Hash</ListGroup.Item>
                    <ListGroup.Item><span style={{ fontFamily: "Roboto" }}>{props.jsonobj.md5hash}</span></ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <s.SpacerMedium />
              <Row>
                <Col>
                  <ListGroup variant="flush" >
                    <ListGroup.Item disabled><i className="bi bi-info-circle"> </i></ListGroup.Item>
                    <ListGroup.Item>
                    <p style={{ lineHeight: "1.6"}}>It may take a while for OpenSea to fetch the metadata and image from IPFS.</p></ListGroup.Item>
                    <ListGroup.Item><span style={{ fontSize: "12px", fontFamily: "Roboto"}}>View transaction <a className="NFTlink" style={{color:'#d4aa04'}} target="_blank" rel="noreferrer" href={`https://polygonscan.com/tx/${props.txnhash}`}> <span style={{color:'#d4aa04', fontFamily: "Roboto" }}>{props.txnhash.slice(0, 2) }{props.txnhash.slice(2, 6).toUpperCase() }...{props.txnhash.slice(props.txnhash.length-4, props.txnhash.length).toUpperCase() }</span> <i className="bi bi-front" style={{  cursor: 'pointer'}} ></i></a></span></ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <s.SpacerMedium />
          </>
        ):(
          <>
          <Row>
            <Col>
              <ListGroup variant="flush" >
                <ListGroup.Item disabled><i className="bi bi-info-circle"></i> <span>No data available.</span></ListGroup.Item>
                <ListGroup.Item>
                <p style={{ lineHeight: "1.6"}}>It may take a while to fetch the metadata and image from IPFS. You can also view the NFT on your OpenSea account.</p></ListGroup.Item>
                <ListGroup.Item><span style={{ fontSize: "12px", fontFamily: "Roboto"}}>View transaction <a className="NFTlink" style={{color:'#d4aa04'}} target="_blank" rel="noreferrer" href={`https://polygonscan.com/tx/${props.txnhash}`}> <span style={{color:'#d4aa04', fontFamily: "Roboto" }}>{props.txnhash.slice(0, 2) }{props.txnhash.slice(2, 6).toUpperCase() }...{props.txnhash.slice(props.txnhash.length-4, props.txnhash.length).toUpperCase() }</span> <i className="bi bi-front" style={{  cursor: 'pointer'}} ></i></a></span></ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{alignItems: "center"}} >
        <StyledButton style={{width: "100%"}} onClick={props.onHide}>Close</StyledButton>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState(``);
  const [NFTimage, setNFTimage] = useState(`/metadata/0.png`);
  const [NFTname, setNFTname] = useState(``);
  const [NFTid, setNFTid] = useState(``);
  const [NFThash, setNFThash] = useState(``);
  const [NFTjsonarray, setNFTjsonarray] = useState(``);
  const [NFTtxnhash, setNFTtxnhash] = useState(``);
  const [claimingNft, setClaimingNft] = useState(false);
  const [MintSuccess, setMintSuccess] = useState(false);
  const ipfsGateway = "https://gateway.ipfs.io/ipfs";
  const ipfsJsonCID = "QmRqfCJZHsRgMzzUogXE6GQE6muqgNjJXNM2de5jDA6W9y";
  const ipfsPNGCID = "QmXoLGqmfro1Lr7xTX85xyWLJrkPSAHgYYoDSEEXTzpsGg";
  const [modalShow, setModalShow] = React.useState(false);


  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting a Pixel Wraith...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: "285000",
        to: "0xB23d845EC68Ed016830Bd3937C25657A9b0F1369",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(
          ((Number(data.cost) / 1e18) * _amount).toString(),
           "ether"
        ),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong. Please check polygonscan.com for any pending transactions.");
        setClaimingNft(false);
        //setMintSuccess(false);
      })
      .then((receipt) => {
        let tokenId = receipt.events.Transfer.returnValues.tokenId;

        // clear vars
        setNFTjsonarray('');
        setNFTname('');
        setNFThash('');
        setNFTid('');
                
        setFeedback("Pulling metadata...");
        // copy nft to metadata folder
        copyTempNFT(tokenId).then(() => {
          loadNFTjson(tokenId);
          // loadNFTjson(tokenId);
          setNFTimage(`/metadata/image/${tokenId}.png`);
        });
        
        // ipfs link
        // setNFTimage(`${ipfsGateway}/${ipfsPNGCID}/${tokenId}.png`);

        setNFTtxnhash(receipt.events.Transfer.transactionHash);
        setFeedback(
          `Pixel Wraith #${tokenId} NFT has been minted into your wallet.`
        );
        setClaimingNft(false);
        setMintSuccess(true);
        dispatch(fetchData(blockchain.account));
      });
  };

  const loadNFTjson = async (_tokenId) => {
    // let NFTjson = await fetch(`/metadata/json/${_tokenId}.json`).then((response) => response.json());
    const NFTjson = await fetch(`/metadata/json/${_tokenId}.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then((data) => {
      return data;
    });

    // pinata
    // const NFTjson = await fetch (`${ipfsGateway}/${ipfsJsonCID}/${_tokenId}.json`).then(response => response.json());
    // const NFTjson = await response.json();
    // if (!NFTjson.ok) { console.log(NFTjson.statusText); };

    setNFTname(NFTjson.name);
    setNFThash(NFTjson.md5hash);
    setNFTid(`No.${NFTjson.edition},`);
    setNFTjsonarray(NFTjson);
    
  };

  const copyTempNFT = async (_tokenId) => {
    const statusCopyTempNFT = await fetch(`api.php?id=${_tokenId}`).then(response => response.statusText);
  }

  const loadingGif = () => {
    return (
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>  
      )
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--black)", backgroundImage: `url(${bg})` }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
      <img className="logo" alt={"pixel-wraith"} src={logo} width={"500"}/>
        <s.TextTitle
          style={{ textAlign: "center", fontSize: 12, fontWeight: "normal", color: "#FFE0B2" }}
        >
        </s.TextTitle>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} ai={"center"}>
            <s.TextTitle
              style={{ textAlign: "center", fontSize: 35, fontWeight: "normal", color: "#FFE0B2"  }}
            >
              <span className="titleMinted">{data.totalSupply} of 5,000 conjured.</span>
            </s.TextTitle>
            {Number(data.totalSupply) == 5000 ? (
              <>
              <s.SpacerSmall />
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center", color: "#FFCC80" }}>
                  You can still find Pixel Wraiths for sale on{" "}
                  <a
                    className="NFTlink"
                    style={{color:'#FFCC80'}} 
                    target={"_blank"}
                    rel="noreferrer"
                    href={"https://opensea.io/collection/pixel-wraiths"}
                  >
                    OpenSea
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "#FFE0B2"  }}>
                  <span className="mintCost">Minting a Pixel Wraith NFT costs {data.cost / 1e18} Matic*.</span>
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center", fontSize: "14px" }}>
                  *Excluding gas fee for the Great Polygon.
                </s.TextDescription>
                <s.TextDescription style={{ textAlign: "center", fontSize: "14px" }}>
                  Each random generative wraith is unique.
                </s.TextDescription>
                <s.TextDescription style={{ textAlign: "center", fontSize: "14px" }}>
                  No two alike.
                </s.TextDescription>
                <s.SpacerMedium />
                <StyledImg alt={"pixel-wraith"} src={NFTimage} key={Date.now()}/>

                <s.SpacerSmall />
                <s.TextTitle style={{ textAlign: "center", color: "#FFE0B2", fontSize: 24,  }}>
                  {NFTid} {NFTname} 
                </s.TextTitle>
                <>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    name={NFTname}
                    id={NFTid}
                    jsonobj={NFTjsonarray}
                    txnhash={NFTtxnhash}
                  />
                </>
                <s.SpacerXSmall />
                <s.TextTitle style={{ textAlign: "center", color: "#FFE0B2", fontSize: 12,  }}>
                  
                  {MintSuccess ? (
                    <span>
                      <span style={{ cursor: 'pointer', color:'#FFCC80' }} onClick={() => setModalShow(true)}>View Wraith Details <i className="bi bi-front" ></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span>Txn Hash: <a className="NFTlink" style={{color:'#FFCC80'}} target="_blank" rel="noreferrer" href={`https://polygonscan.com/tx/${NFTtxnhash}`}> <span style={{color:'#FFCC80', fontFamily: "Roboto" }}>{NFTtxnhash.slice(0, 2) }{NFTtxnhash.slice(2, 6).toUpperCase() }...{NFTtxnhash.slice(NFTtxnhash.length-4, NFTtxnhash.length).toUpperCase() }</span> <i className="bi bi-front" style={{  cursor: 'pointer'}} ></i></a></span>
                    </span>
                  ):(
                    <span> </span>
                  )}

                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center" }}>
                    <span style={{color:'red'}}>&bull; </span> Connect MetaMask to the Polygon network.
                    </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"center"} jc={"center"} >
                    <StyledButton
                      disabled={claimingNft || !data.cost ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      {claimingNft || !data.cost  ? loadingGif() : "MINT A PIXEL WRAITH"}
                    </StyledButton>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center"}}>
                    <span style={{color:'#FFCC80'}}>&bull; </span> MetaMask Account: <span style={{color:'#FFCC80', fontFamily: "Roboto" }}>{blockchain.account.slice(0, 2) }{blockchain.account.slice(2, 6).toUpperCase() }...{blockchain.account.slice(blockchain.account.length-4, blockchain.account.length).toUpperCase() }</span>
                    </s.TextDescription>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 11 }}><span style={{color:'#FFCC80'}}>&bull; </span>
          <span style={{ fontFamily: "Roboto" }}>If no image or metadata appears after minting, please view the minted NFT in your OpenSea account.</span>
          </s.TextDescription>
          <s.TextDescription style={{ textAlign: "center", fontSize: 11 }}><span style={{color:'#FFCC80'}}>&bull; </span>
          <span style={{ fontFamily: "Roboto" }}>Make sure your MetaMask is connected to the Polygon Mainnet and is using the correct wallet address.</span>
          </s.TextDescription>
          <s.TextDescription style={{ textAlign: "center", fontSize: 11 }}><span style={{color:'#FFCC80'}}>&bull; </span>
          <span style={{ fontFamily: "Roboto" }}>Once the minting process starts, you cannot undo this action. We have set the gas limit to 285000 for the contract to successfully mint the NFT. We recommend that you don't change the gas limit.</span>
          </s.TextDescription>
        <s.SpacerXSmall />
          <s.TextDescription style={{ textAlign: "center", fontSize: 11 }}>
          <span style={{color:'#FFCC80'}}>&bull; </span> <span style={{ fontFamily: "Roboto" }}> </span><span style={{color:'#FFCC80'}}>&bull; </span>
          </s.TextDescription>
        </s.Container>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
          <span style={{ color:'#FFCC80' }}>The Great Yard</span>
        </s.Container>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
        <span style={{ fontFamily: "Roboto",color:'#FFCC80', fontSize: 11  }}>pixelwraiths@gmail.com</span>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
